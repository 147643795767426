import styled from 'styled-components'
import ContainerBase from 'components/Styled/Container'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 8rem 0;
`

export const MainImage = styled.picture`
  display: block;
  flex-shrink: 0;

  ${props => props.theme.mq.small.css`
    order: 10;
    max-width: 100%;
    margin-right: -2rem;
    margin-bottom: -10vw;
    align-self: flex-end;
  `}

  ${props => props.theme.mq.medium.css`
    margin: 0 6rem;
    &, img { height: 45rem; }
  `}

  ${props => props.theme.mq.large.css`
    margin: 0 10rem;
  `}
`

export const Container = styled(ContainerBase)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;

  ${props => props.theme.mq.mediumUp.css`
    align-items: center;
  `}
`

export const Content = styled.div`
  display: flex;

  ${props => props.theme.mq.small.css`
    &, & > div { display: contents; }
  `}

  ${props => props.theme.mq.mediumUp.css`
    align-items: center;

    p {
      padding-right: 6rem;
    }
  `}
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 4rem;

  ${props => props.theme.mq.small.css`
    flex-direction: space-around;
    width: 100%;
    order: 20;
  `}

  ${props => props.theme.mq.mediumUp.css`
    justify-content: space-around;
    width: 70%;
  `}
`

export const Numbers = styled.ul`
  display: flex;
  text-align: center;

  ${props => props.theme.mq.small.css`
    margin: 4rem 0;
    order: 15;
  `}

  ${props => props.theme.mq.mediumUp.css`
    margin: 6rem 0 3rem;
  `}
`

export const NumbersItem = styled.li`
  flex: 1;

  h3 {
    display: block;
    margin: 1.5rem 0 0;
    font-size: 3.2rem;
    font-weight: 600;
    color: ${props => props.theme.colors.headings};
  }

  span {
    display: block;
    margin-top: 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #a09aa5;
  }

  svg {
    font-size: 4rem;
    color: ${props => props.theme.colors.primary};
  }

  ${props => props.theme.mq.small.css`
    &:nth-child(2) ~ & {
      display: none;
    }
  `}
`
