import React from 'react'
import Props from 'prop-types'

import Page from 'components/Page'
import Header from './Header'
import About from './About'

class Homepage extends React.Component {
  handleArrowClick = () => {
    if (this.aboutSection) {
      this.aboutSection.scroll()
    }
  }

  render() {
    const {
      lang: { title },
      lang,
    } = this.props

    const wholeTitle = `${title.a} ${title.b} ${title.c}`

    return (
      <Page title={wholeTitle} translates={lang}>
        <Header onArrowClick={this.handleArrowClick} translates={lang} />
        <About
          translates={lang}
          ref={aboutSection => {
            this.aboutSection = aboutSection
          }}
        />
      </Page>
    )
  }
}

Homepage.propTypes = {
  lang: Props.shape({
    title: Props.shape({
      a: Props.string.isRequired,
      b: Props.string.isRequired,
      c: Props.string.isRequired,
    }),
  }).isRequired,
}

export default Homepage
