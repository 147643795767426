import styled from 'styled-components'
import { theme } from 'common/styles'

export const StoreBadge = styled.span`
  align-items: center;
  padding: 2rem 3.2rem;
  ${theme.mq.small.css`
    padding: 1.8rem 0 1.6rem .8rem;
  `}
`
