import styled, { keyframes, css } from 'styled-components'
import Container from 'components/Styled/Container'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 0 8rem;
  color: white;
  overflow: hidden;
  background: ${props => props.theme.colors.primary};

  &:after {
    display: block;
    content: '';
    flex-grow: 5;
  }

  ${Container} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${props => props.theme.mq.small.css`
    padding: 0 0 6rem;

    &:before { flex-grow: 1; }
    &:after { flex-grow: 0; }

    ${Container} {
      flex-grow: 2;
    }
  `}

  ${props => props.theme.mq.medium.css`
    padding-left: 4rem;
    padding-right: 4rem;
  `}
`

export const Heading = styled.h1`
  padding-right: 30rem;
  font-size: 8rem;
  font-weight: bold;

  span {
    display: block;
    font-weight: normal;
    font-size: 4.8rem;
  }
  
  ${props => props.theme.mq.small.css`
    padding-right: 0;
    font-size: 4rem;

    span { font-size: 2.4rem; }
    br { display: none; }
  `}
  
  ${props =>
    props.slug === 'tw' &&
    props.theme.mq.small.css &&
    css`
      br {
        display: block;
      }
    `}

  ${props => props.theme.mq.medium.css`
    padding-right: 0;
    font-size: 7rem;

    span { font-size: 4rem; }
  `}
`

export const MainImage = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: ${props => (props.theme.sizes.large / 2 - 340) / 10}rem;
  max-height: 85%;

  ${props => props.theme.mq.mediumDown.css`
    display: none;
  `}
`

const arrowAnimation = keyframes`
  from { transform: rotateZ(45deg); }
  to   { transform: translateY(1.4rem) rotateZ(45deg); }
`

export const Arrow = styled.button`
  position: absolute;
  display: block;
  bottom: 2rem;
  left: 50%;
  padding: 1rem;
  margin-left: -2.2rem;
  border: none;
  appearance: none;
  cursor: pointer;
  background: none;

  &:before {
    content: '';
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotateZ(45deg);
    transition: all 0.3s;
    opacity: 0.5;
  }

  &:hover,
  &:active {
    &:before {
      opacity: 1;
      animation: 0.5s ${arrowAnimation} ease-in-out infinite alternate;
    }
  }
`

export const Spacer = styled.span`
  display: block;
  flex: 5;

  ${props => props.theme.mq.small.css`
    flex: 1;
  `}
`
